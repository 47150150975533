/* ==== Global Account Styles ==== */
.accountCard {
  display: flex;

  cursor: pointer;

  width: 95%;
  padding: 10px;
  margin: 10px 0;

  background-color: map(colors, background, value_4);
  border: 2px solid map(colors, background, value_9_white);
  border-radius: 5px;

  .accountIcon {
    padding-right: 10px;
    font-size: map(fontSizes, xlarge);
  }
}

.accountCard:hover {
  border-color: map(colors, branding, primary);
  background-color: map(colors, branding, primary);
  color: map(colors, typography, darkBackgroundText);
  filter: drop-shadow(10px 5px 4px map(colors, background, value_3));
}

.isSelected {
  border: 2px solid map(colors, branding, primary);
}

.isDisabled {
  background-color: map(colors, typography, disabled);
  cursor: not-allowed;
  pointer-events: none;
}

/* ==== Account Selection Form ====  */
.accountSelectionForm {
  .accountSelectionCard {
    display: flex;
    flex-direction: column;

    width: 95%;
    padding: 10px;
    margin: 10px 0;

    background-color: map(colors, background, value_4);
  }

  .buttonRowWrapper {
    padding-top: 10px;

    > * {
      padding-bottom: 10px;
      margin-right: 10px;
    }
  }
}

/* ==== AccountCreationForm ====  */
.wrapper {
  display: flex;
  flex-direction: column;

  .button {
    width: 230px;
    height: 80px;

    cursor: pointer;

    border-radius: 5px;
    border: 2px solid map(colors, background, value_4);

    margin: 10px 10px;

    font-weight: map(fontWeight, bold);
    font-size: map(fontSizes, xlarge);
  }

  .button:hover {
    border-color: map(colors, branding, primary);
    background-color: map(colors, branding, primary);
    color: map(colors, typography, darkBackgroundText);
    filter: drop-shadow(10px 5px 4px map(colors, background, value_3));
  }

  .buttonRowWrapper {
    display: flex;
    flex-wrap: wrap;

    .isSelected {
      border: 2px solid map(colors, branding, primary);
    }
  }

  .contentRowWrapper {
    padding-top: 20px;
  }

  .contentHeader {
    font-weight: map(fontSizes, bold);
    font-size: map(fontSizes, large);

    padding-bottom: 10px;
  }
}

@media (min-width: map(breakpoints, generic, 800)) {
  .wrapper {
    flex-direction: row;

    .contentRowWrapper {
      padding: 0 2%;

      width: 50%;
    }

    .buttonRowWrapper {
      display: unset;
      width: 50%;

      > div:first-child {
        margin-top: unset;
      }
    }
  }
}

@media (min-width: map(breakpoints, generic, 1200)) {
  .wrapper {
    .contentRowWrapper {
      width: 70%;
    }

    .buttonRowWrapper {
      width: 30%;
    }
  }
}

/* DarkMode Styles */
.darkMode {
  .wrapper {
    .button:hover {
      border-color: map(colors, branding, secondary);
      background-color: map(colors, darkMode, hoverColor);
      filter: none;
    }
  }

  .accountSelectionCard,
  .accountCard {
    border-color: map(colors, background, value_2);
    background-color: map(colors, background, value_2);
  }
}
